import React, { useState } from "react";

import Header from "../../components/Header";

import { useAppDispatch } from "../../reducers";

import { sendGroupMail } from "../../actions/adminAction";

export default function Emails() {
  const dispatch = useAppDispatch();

  const [object, setObject] = useState("");
  const [content, setContent] = useState("");

  return (
    <div className="my-ads-page">
      <Header />

      <main>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Mail groupé</h1>
        </div>
        <div className="form-group">
          <label>Objet</label>
          <input
            type="text"
            value={object}
            onChange={(e) => setObject(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Contenu</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          ></textarea>
          <button
            style={{ width: "auto" }}
            onClick={() => {
              if (
                window.confirm(
                  "Êtes-vous sûr de vouloir envoyer un mail à tous les utilisateurs ?"
                )
              ) {
                dispatch(sendGroupMail(object, content));
              }
            }}
          >
            Envoyer
          </button>
        </div>
      </main>
    </div>
  );
}
