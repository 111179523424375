import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import LinkedInShareButton from "../../components/LinkedInShareButton";
import FacebookShareButton from "../../components/FacebookShareButton";
import WhatsAppShareButton from "../../components/WhatsAppShareButton";

import { useAppDispatch } from "../../reducers";

import { getAdMini } from "../../actions/adAction";

import { TbaseReducer } from "../../types/reducersType";

import { useIsMobile } from "../../utils/mobile";

export default function Ad() {
  const location = useLocation();
  let { token } = useParams();

  const isMobile = useIsMobile();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ad } = useSelector((state: TbaseReducer) => state.adReducer);

  useEffect(() => {
    if (token) {
      dispatch(getAdMini(token));
    }
  }, [dispatch, token]);

  if (!ad) {
    return null;
  }

  const SigninItem = () => {
    return (
      <div className="ad-price-section">
        <div className="ad-price-container">
          <p style={{ maxWidth: 300 }}>
            Veuillez vous connecter ou demander vos accès pour avoir d'avantages
            d'informations sur l'annonce
          </p>

          <button className="red" onClick={() => navigate(`/`)}>
            Se connecter
          </button>
        </div>
      </div>
    );
  };

  const textShare = `🚗 À saisir ! ${ad.characteristics.marque} ${ad.characteristics.modele} comptabilisant ${ad.characteristics.kilometrage} km, disponible.
👉 Découvrez l'annonce ici :`;

  return (
    <>
      <Helmet>
        <title>{ad.title} - CarsForPro</title>
        <meta
          name="description"
          content="This is the home page of my website"
        />
        <meta
          name="keywords"
          content={`${[
            ...ad.options.comfort,
            ...ad.options.design,
            ...ad.options.security,
            ...ad.options.technology,
          ].join(", ")}`}
        />
        <meta property="og:title" content={`${ad.title} - CarsForPro`} />
        <meta
          property="og:description"
          content={ad.description.substring(0, 160)}
        />
        <meta
          property="og:image"
          content={`https://${window.location.hostname}/api/ad/picture/${ad.photos[0]}`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="ad-detail-page">
        <main>
          <Link to={".."} className="back-link">
            ← Se connecter
          </Link>

          <div className="ad-detail-container">
            {isMobile ? <SigninItem /> : null}
            <div className="ad-detail-main">
              <div className="ad-images">
                <img src={`/api/ad/picture/${ad.photos[0]}`} alt="main" />
              </div>
              {isMobile ? null : <SigninItem />}
            </div>

            <div className="ad-description">
              <h1>
                {ad.title}{" "}
                <i style={{ color: "#af4154", fontWeight: "normal" }}>
                  {ad.sold ? "- Le véhicule est vendu" : ""}
                </i>
              </h1>
              <p>{ad.department}</p>
              <p>{ad.description}</p>
            </div>

            <div className="ad-characteristics">
              <h2>Caractéristiques</h2>
              <table>
                <tbody>
                  {Object.entries(ad.characteristics).map(([key, value]) => (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="ad-options">
              <h2>Équipements et options</h2>
              <div className="option-category">
                <h3>Confort</h3>
                <ul>
                  {ad.options.comfort.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </ul>
              </div>
              <div className="option-category">
                <h3>Technologie</h3>
                <ul>
                  {ad.options.technology.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </ul>
              </div>
              <div className="option-category">
                <h3>Sécurité</h3>
                <ul>
                  {ad.options.security.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </ul>
              </div>
              <div className="option-category">
                <h3>Design</h3>
                <ul>
                  {ad.options.design.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
            <LinkedInShareButton text={textShare} />
            <FacebookShareButton text={textShare} />
            <WhatsAppShareButton text={textShare} />
          </div>
          <p>
            {textShare} {window.location.href}
          </p>
        </main>
      </div>
    </>
  );
}
