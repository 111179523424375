import React from "react";
import { useLocation } from "react-router-dom";

interface FacebookShareButtonProps {
  text: string;
}

const FacebookShareButton = ({ text }: FacebookShareButtonProps) => {
  const location = useLocation();

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    window.location.href
  )}&quote=${encodeURIComponent(text)}`;

  const handleFacebookShare = () => {
    window.open(facebookUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <button
      onClick={handleFacebookShare}
      style={{
        width: "auto",
        marginTop: 20,
        backgroundColor: "#1877F2",
        color: "white",
        border: "none",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: "pointer",
      }}
    >
      Partager sur Facebook
    </button>
  );
};

export default FacebookShareButton;
