import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import Loader from "./components/Loader";
import CookieBanner from "./components/CookieBanner";

import Dashboard from "./layouts/Dashboard";
import Auth from "./layouts/Auth";

import Signin from "./views/auth/Signin";
import Forgot from "./views/auth/Forgot";
import Home from "./views/dashboard/Home";
import Ad from "./views/dashboard/Ad";
import AdMini from "./views/dashboard/AdMini";
import AddAd from "./views/dashboard/AddAd";
import MyAds from "./views/dashboard/MyAds";
import Accounts from "./views/dashboard/Accounts";
import Offers from "./views/dashboard/Offers";
import Emails from "./views/dashboard/Emails";
import MyAccount from "./views/dashboard/MyAccount";

import Legal from "./views/auth/Legal";
import Condition from "./views/auth/Condition";

import { useAppDispatch } from "./reducers";

import { refreshToken } from "./actions/authAction";

import { TbaseReducer } from "./types/reducersType";

function App() {
  const dispatch = useAppDispatch();

  const { isAuthenticated, userId } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const { isLoad } = useSelector((state: TbaseReducer) => state.loadReducer);

  useEffect(() => {
    if (!userId) {
      dispatch(refreshToken());
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(refreshToken());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/legal"
              element={
                <Auth>
                  <Legal />
                </Auth>
              }
            />
            <Route
              path="/condition"
              element={
                <Auth>
                  <Condition />
                </Auth>
              }
            />
            {!isAuthenticated ? (
              <>
                <Route
                  path="*"
                  element={
                    <Auth>
                      <Signin />
                    </Auth>
                  }
                />
                <Route
                  path="/forgot"
                  element={
                    <Auth>
                      <Forgot />
                    </Auth>
                  }
                />
                <Route
                  path="/ad/:token"
                  element={
                    <Dashboard>
                      <AdMini />
                    </Dashboard>
                  }
                />
              </>
            ) : (
              <>
                <Route
                  path="/"
                  element={
                    <Dashboard>
                      <Home />
                    </Dashboard>
                  }
                />
                <Route
                  path="/ad/:token"
                  element={
                    <Dashboard>
                      <Ad />
                    </Dashboard>
                  }
                />
                <Route
                  path="/add-ad"
                  element={
                    <Dashboard>
                      <AddAd />
                    </Dashboard>
                  }
                />
                <Route
                  path="/edit-ad/:token"
                  element={
                    <Dashboard>
                      <AddAd />
                    </Dashboard>
                  }
                />
                <Route
                  path="/my-ads"
                  element={
                    <Dashboard>
                      <MyAds />
                    </Dashboard>
                  }
                />
                <Route
                  path="/admin-accounts"
                  element={
                    <Dashboard>
                      <Accounts />
                    </Dashboard>
                  }
                />
                <Route
                  path="/admin-offers"
                  element={
                    <Dashboard>
                      <Offers />
                    </Dashboard>
                  }
                />
                <Route
                  path="/admin-emails"
                  element={
                    <Dashboard>
                      <Emails />
                    </Dashboard>
                  }
                />
                <Route
                  path="/account"
                  element={
                    <Dashboard>
                      <MyAccount />
                    </Dashboard>
                  }
                />
              </>
            )}
          </Routes>
        </BrowserRouter>
        {isLoad ? <Loader /> : null}
        <CookieBanner />
      </HelmetProvider>
    </>
  );
}

export default App;
