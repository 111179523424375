import { _fetch } from "../utils/fetch";

export const refreshToken = (token?: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/refresh-token",
      "REFRESH_TOKEN",
      JSON.stringify({
        token,
      }),
      dispatch
    );
  };
};

export const signin = (
  email: string,
  password: string,
  isRemember: boolean
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/signin",
      "SIGNIN",
      JSON.stringify({
        email,
        password,
        isRemember,
      }),
      dispatch
    );
  };
};

export const signout = () => {
  return (dispatch: any) => {
    _fetch("/api/auth/signout", "SIGNOUT", JSON.stringify({}), dispatch);
  };
};

export const updateAccount = (
  name: string,
  email: string,
  phone: string,
  siret: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/update-account",
      "UPDATE_ACCOUNT",
      JSON.stringify({
        name,
        email,
        phone,
        siret,
      }),
      dispatch
    );
  };
};

export const updateAccountNotifications = (notifications: string[]) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/update-account-notifications",
      "UPDATE_ACCOUNT_NOTIFICATIONS",
      JSON.stringify({
        notifications,
      }),
      dispatch
    );
  };
};

export const updateAccountPassword = (
  oldPassword: string,
  newPassword: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/update-account-password",
      "UPDATE_ACCOUNT_PASSWORD",
      JSON.stringify({
        oldPassword,
        newPassword,
      }),
      dispatch
    );
  };
};

export const forgotPassword = (email: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/forgot",
      "FORGOT_PASSWORD",
      JSON.stringify({
        email,
      }),
      dispatch
    );
  };
};

export const requestAccount = (email: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/auth/request-account",
      "REQUEST_ACCOUNT",
      JSON.stringify({
        email,
      }),
      dispatch
    );
  };
};
