import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import soldImage from "../../images/sold.svg";

import { getCurrencyValue } from "../../utils/general";

type AdProps = {
  sold: number;
  photo: string;
  title: string;
  description: string;
  price: string;
  priceType: string;
  department: string;
  token: string;
  dateSchedule: string;
};

export default function Ad({
  sold,
  photo,
  title,
  description,
  price,
  priceType,
  department,
  token,
  dateSchedule,
}: AdProps) {
  const navigate = useNavigate();

  return (
    <div className="ad-item">
      <div
        className="image-container"
        style={
          sold
            ? {
                backgroundImage: `url(${soldImage})`,
              }
            : {}
        }
      >
        <img src={`/api/ad/picture/${photo}`} alt="car" />
      </div>
      <div className="ad-details">
        <h3>
          {title}{" "}
          <i style={{ color: "red", fontWeight: "normal" }}>
            {dateSchedule
              ? `- Expire dans ${moment(dateSchedule).diff(
                  moment(),
                  "hours"
                )} heure(s) et ${
                  moment(dateSchedule).diff(moment(), "minutes") % 60
                } minute(s)`
              : null}
          </i>
        </h3>
        <p>{description}</p>
        <p>{department}</p>
        <div className="ad-footer">
          {price ? (
            <div className="ad-price">
              <span>{getCurrencyValue(parseInt(price))} </span>
              <span>{priceType}</span>
            </div>
          ) : null}
          <button onClick={() => navigate(`/ad/${token}`)}>
            voir l'annonce
          </button>
        </div>
      </div>
    </div>
  );
}
