import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment";

import { TbaseReducer } from "../../types/reducersType";

import { useAppDispatch } from "../../reducers";

import { updateOffer } from "../../actions/offerAction";

import { getOffers } from "../../actions/offerAction";

import Header from "../../components/Header";

export default function Admin() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOffers());
  }, [dispatch]);

  const { offers } = useSelector((state: TbaseReducer) => state.offerReducer);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = offers.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(offers.length / itemsPerPage);

  // @ts-ignore
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % offers.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="my-ads-page">
      <Header />

      <main>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Liste des offres</h1>
        </div>
        <div className="ads-list" style={{ overflowX: "auto" }}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Titre</th>
                <th>Annonceur</th>
                <th>Dépositaire</th>
                <th>Montant</th>
                <th>Commentaire</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((offer, idx) => (
                <tr key={idx}>
                  <td>{idx + 1 + itemOffset}</td>
                  <td>{moment(offer.date).format("DD/MM/YYYY")}</td>
                  <td>{offer.adTitle}</td>
                  <td>{offer.ownerUserName}</td>
                  <td>{offer.userName}</td>
                  <td>{offer.price}</td>
                  <td>{offer.comment}</td>
                  <td>
                    {offer.status === 0 ? (
                      <div style={{ display: "flex", gap: 5 }}>
                        <button onClick={() => {}}>Voir l'annonce</button>
                        <button
                          className="red"
                          onClick={() => {
                            dispatch(updateOffer(offer.id, 1));
                          }}
                        >
                          Refuser
                        </button>
                        <button
                          onClick={() => {
                            dispatch(updateOffer(offer.id, 2));
                          }}
                        >
                          Accepter
                        </button>
                      </div>
                    ) : null}
                    {offer.status === 1 ? <span>Refusée</span> : null}
                    {offer.status === 2 ? <span>Validée</span> : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Suivant"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Précèdent"
            renderOnZeroPageCount={null}
          />
        </div>
      </main>
    </div>
  );
}
