import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import Header from "../../components/Header";
import Ad from "../../components/list/Ad";
import Filters from "../../components/list/Filters";

import { Tad } from "../../types/ad/adType";
import { TbaseReducer } from "../../types/reducersType";

import { useAppDispatch } from "../../reducers";

import { getAds } from "../../actions/adAction";

export default function Home() {
  const dispatch = useAppDispatch();

  const { ads, filters } = useSelector(
    (state: TbaseReducer) => state.adReducer
  );

  useEffect(() => {
    dispatch(getAds(filters));
  }, [dispatch]);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 12;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = ads.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(ads.length / itemsPerPage);

  // @ts-ignore
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % ads.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="home-page">
      <Header />
      <main>
        <h1>Trouver des annonces</h1>
        <p>
          Parcourez des milliers d'annonces de voitures d'occasion de toutes
          marques et modèles, Utilisez notre barre de recherche et nos filtres
          avancés pour affiner vos choix et accéder rapidement aux meilleures
          offres du marché.
        </p>

        <Filters />

        <div className="ads-count">{ads.length} annonces</div>

        <div className="ads-list">
          {currentItems.map((ad: Tad, index: number) => (
            <Ad
              key={index}
              photo={ad.photos[0]}
              sold={ad.sold}
              title={ad.title}
              description={ad.description}
              department={ad.department}
              price={ad.price}
              priceType={ad.priceType}
              token={ad.token}
              dateSchedule={ad.dateSchedule}
            />
          ))}
        </div>

        <div className="pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Suivant"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Précèdent"
            renderOnZeroPageCount={null}
          />
        </div>
      </main>
    </div>
  );
}
