import { TcarCharacteristics, Tfilters } from "../types/ad/adType";

import { _fetch } from "../utils/fetch";

export const getAds = (filters: Tfilters) => {
  return (dispatch: any) => {
    _fetch("/api/ad/get-ads", "GET_ADS", JSON.stringify({ filters }), dispatch);
  };
};

export const getMyAds = () => {
  return (dispatch: any) => {
    _fetch("/api/ad/get-my-ads", "GET_MY_ADS", JSON.stringify({}), dispatch);
  };
};

export const getAd = (token: string) => {
  return (dispatch: any) => {
    _fetch("/api/ad/get-ad", "GET_AD", JSON.stringify({ token }), dispatch);
  };
};

export const getAdMini = (token: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/ad/get-ad-mini",
      "GET_AD",
      JSON.stringify({ token }),
      dispatch
    );
  };
};

export const addAd = (
  title: string,
  price: string,
  priceType: string,
  department: string,
  departmentCode: string,
  description: string,
  characteristics: TcarCharacteristics,
  optionsComfort: string[],
  optionsTechnology: string[],
  optionsSecurity: string[],
  optionsDesign: string[],
  photos: string[],
  dateSchedule: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/ad/add-ad",
      "ADD_AD",
      JSON.stringify({
        title,
        price,
        priceType,
        department,
        departmentCode,
        description,
        characteristics,
        optionsComfort,
        optionsTechnology,
        optionsSecurity,
        optionsDesign,
        photos,
        dateSchedule,
      }),
      dispatch
    );
  };
};

export const editAd = (
  token: string,
  title: string,
  price: string,
  priceType: string,
  department: string,
  description: string,
  characteristics: TcarCharacteristics,
  optionsComfort: string[],
  optionsTechnology: string[],
  optionsSecurity: string[],
  optionsDesign: string[],
  photos: string[],
  dateSchedule: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/ad/edit-ad",
      "EDIT_AD",
      JSON.stringify({
        token,
        title,
        price,
        priceType,
        department,
        description,
        characteristics,
        optionsComfort,
        optionsTechnology,
        optionsSecurity,
        optionsDesign,
        photos,
        dateSchedule,
      }),
      dispatch
    );
  };
};

export const addPicture = (base64: string) => {
  return async (dispatch: any) => {
    await _fetch(
      "/api/ad/add-picture",
      "ADD_PICTURE",
      JSON.stringify({
        base64,
      }),
      dispatch
    );
  };
};

export const soldAd = (token: string, sold: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/ad/sold-ad",
      "SOLD_AD",
      JSON.stringify({
        token,
        sold,
      }),
      dispatch
    );
  };
};

export const deleteAd = (token: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/ad/delete-ad",
      "DELETE_AD",
      JSON.stringify({
        token,
      }),
      dispatch
    );
  };
};

export const getReport = (
  name: string,
  firstname: string,
  licensePlate: string,
  formula: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/ad/get-report",
      "GET_REPORT",
      JSON.stringify({
        name,
        firstname,
        licensePlate,
        formula,
      }),
      dispatch
    );
  };
};
