// CookieBanner.tsx
import React, { useState, useEffect } from "react";

const CookieBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowBanner(false);
  };

  const handleReject = () => {
    localStorage.setItem("cookieConsent", "rejected");
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div style={bannerStyle}>
      <p style={{ color: "#fff" }}>
        Nous utilisons des cookies pour vous identifier sur le site et assurer
        son bon fonctionnement. Acceptez-vous l'utilisation des cookies
        facultatifs ?
      </p>
      <div style={buttonContainerStyle}>
        <button onClick={handleAccept} style={buttonStyle}>
          Accepter
        </button>
        <button onClick={handleReject} style={buttonStyle}>
          Refuser
        </button>
      </div>
    </div>
  );
};

// Styles inline (vous pouvez aussi utiliser un fichier CSS séparé)
const bannerStyle: React.CSSProperties = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  backgroundColor: "#333",
  color: "white",
  textAlign: "center",
  padding: "10px",
  zIndex: 1000,
};

const buttonContainerStyle: React.CSSProperties = {
  display: "flex",
  gap: 10,
  marginTop: "10px",
};

const buttonStyle: React.CSSProperties = {
  margin: "0 10px",
  padding: "10px 20px",
  backgroundColor: "#007BFF",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

export default CookieBanner;
