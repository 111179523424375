import React from "react";
import { useLocation } from "react-router-dom";

interface LinkedInShareButtonProps {
  text: string;
}

const LinkedInShareButton = ({ text }: LinkedInShareButtonProps) => {
  const location = useLocation();

  const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    window.location.href
  )}&summary=${encodeURIComponent(text)}`;

  const handleLinkedInShare = () => {
    window.open(linkedInUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <button
      onClick={handleLinkedInShare}
      style={{
        width: "auto",
        marginTop: 20,
        backgroundColor: "#0A66C2",
        color: "white",
        border: "none",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: "pointer",
      }}
    >
      Partager sur LinkedIn
    </button>
  );
};

export default LinkedInShareButton;
