import { _fetch } from "../utils/fetch";

export const getAccounts = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/get-accounts",
      "GET_ACCOUNTS",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const addAccount = (
  name: string,
  email: string,
  phone: string,
  siret: string,
  notifications: string[],
  addAd: boolean,
  password: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/add-account",
      "ADD_ACCOUNT",
      JSON.stringify({
        name,
        email,
        phone,
        siret,
        notifications,
        addAd,
        password,
      }),
      dispatch
    );
  };
};

export const editAccount = (
  id: number,
  name: string,
  email: string,
  phone: string,
  siret: string,
  notifications: string[],
  addAd: boolean,
  password: string
) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/edit-account",
      "EDIT_ACCOUNT",
      JSON.stringify({
        id,
        name,
        email,
        phone,
        siret,
        notifications,
        addAd,
        password,
      }),
      dispatch
    );
  };
};

export const deleteAccount = (id: number) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/delete-account",
      "DELETE_ACCOUNT",
      JSON.stringify({
        id,
      }),
      dispatch
    );
  };
};

export const sendGroupMail = (object: string, content: string) => {
  return (dispatch: any) => {
    _fetch(
      "/api/admin/send-group-mail",
      "SEND_GROUP_MAIL",
      JSON.stringify({
        object,
        content,
      }),
      dispatch
    );
  };
};
