import React from "react";
import { useLocation } from "react-router-dom";

interface WhatsAppShareButtonProps {
  text: string;
}

const WhatsAppShareButton = ({ text }: WhatsAppShareButtonProps) => {
  const location = useLocation();

  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    text
  )}%20${encodeURIComponent(window.location.href)}`;

  const handleWhatsAppShare = () => {
    window.open(whatsappUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <button
      onClick={handleWhatsAppShare}
      style={{
        width: "auto",
        marginTop: 20,
        backgroundColor: "#25D366",
        color: "white",
        border: "none",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: "pointer",
      }}
    >
      Partager sur WhatsApp
    </button>
  );
};

export default WhatsAppShareButton;
