import {
  Tad,
  TadInitialState,
  Tphoto,
  TfiltersActionData,
} from "../types/ad/adType";

export const initialState = {
  ads: [],
  myAds: [],
  ad: undefined,
  photos: [],
  filters: {
    search: "",
    department: "",
    brand: "",
    model: "",
    gearbox: "",
    fuel: "",
    mileageMin: "",
    mileageMax: "",
    yearMin: "",
    yearMax: "",
  },
  reportVehicle: undefined,
} satisfies TadInitialState;

export const adReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_ADS":
      return {
        ...state,
        ads: action.data,
      };
    case "GET_MY_ADS":
      return {
        ...state,
        myAds: action.data,
      };
    case "GET_AD":
      return {
        ...state,
        ad: action.data,
      };
    case "ADD_PHOTOS":
      return {
        ...state,
        photos: [
          ...state.photos,
          ...action.data.map((p: string) => ({
            base64: p,
          })),
        ],
      };
    case "REMOVE_PHOTO":
      return {
        ...state,
        photos: state.photos.filter(
          (p: Tphoto) =>
            (action.data.base64 && p.base64 !== action.data.base64) ||
            (action.data.oldToken && p.oldToken !== action.data.oldToken)
        ),
      };
    case "SET_PHOTOS":
      return {
        ...state,
        photos: action.data,
      };

    case "ADD_PICTURE":
      const tmpPhotos = state.photos.map((p: Tphoto) => {
        if (p.base64 === action.body.base64) {
          return {
            ...p,
            success: action.success,
            token: action.token,
          };
        } else {
          return p;
        }
      });

      return {
        ...state,
        photos: tmpPhotos,
      };
    case "SOLD_AD":
      return {
        ...state,
        myAds: state.myAds.map((ad: Tad) => {
          if (ad.token === action.body.token) {
            return {
              ...ad,
              sold: !ad.sold,
            };
          } else {
            return ad;
          }
        }),
      };
    case "DELETE_AD":
      return {
        ...state,
        myAds: state.myAds.filter((ad: Tad) => ad.token !== action.body.token),
      };
    case "UPDATE_FILTERS":
      const tmpFilters = JSON.parse(JSON.stringify(state.filters));
      const data = action.data as TfiltersActionData;

      tmpFilters[data.name] = data.value;
      if (data.name === "brand") {
        tmpFilters["model"] = "";
      }

      return {
        ...state,
        filters: tmpFilters,
      };
    case "ADD_AD":
      return {
        ...state,
        ad: undefined,
        photos: [],
      };
    case "EDIT_AD":
      return {
        ...state,
        ad: undefined,
        photos: [],
      };
    case "GET_REPORT":
      return {
        ...state,
        reportVehicle:
          action.success && action.data.vehicle
            ? action.data.vehicle
            : undefined,
      };
    default:
      return state;
  }
};
